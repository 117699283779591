export const ENV =
  window.location.hostname === 'https://splithub-spa.pages.dev/'
    ? 'prod'
    : window.location.hostname.includes('https://splithub-spa-stage.pages.dev/')
      ? 'stage'
      : window.location.port === '4173'
        ? 'test'
        : 'dev';

export const getServerUrl = () => {
  switch (ENV) {
    case 'stage':
      return 'https://splithub-spa-worker-stage.adbro.workers.dev/';
    case 'prod':
      return 'https://splithub-spa-worker.adbro.workers.dev/';
    case 'test':
      return 'http://localhost:8788';
    case 'dev':
    default:
      return 'http://localhost:8787';
  }
};
